import React from "react"
import { Link } from "gatsby"
import logo from "../../static/assets/kikxle.png"

const Logo = props => (
  <div className="site-logo">
    <Link to="/">
      <img src={logo} alt="Kikxle" />
      <span>{props.title}</span>
    </Link>
  </div>
)

export default Logo
