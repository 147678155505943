import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { motion, useCycle } from "framer-motion"

const MenuItems = [
  {
    path: "/projects",
    title: "Projects",
  },
  {
    path: "/about",
    title: "Profile",
  },
  {
    path: "/blog",
    title: "Posts",
  },
]

const listLinkVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const variants = {
  open: {
    right: 0,
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  closed: {
    right: "-100%",
    transition: {
      delay: 0.5,
      staggerChildren: 0.05,
      staggerDirection: -1,
      ease: [0.87, 0, 0.13, 1],
    },
  },
}

const ListLink = props => (
  <motion.li variants={listLinkVariants}>
    <Link to={props.to}>{props.children}</Link>
  </motion.li>
)

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 100%)"
    strokeLinecap="round"
    {...props}
  />
)

export const Navigation = () => {
  const [isOpen, toggleOpen] = useCycle(false, true)

  const listMenuItems = MenuItems.map((menuItem, index) => (
    <ListLink key={index} to={menuItem.path}>
      {menuItem.title}
    </ListLink>
  ))

  React.useEffect(() => {
    isOpen
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "unset")
  }, [isOpen])

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="site-navigation"
    >
      <button
        aria-label="open menu"
        onClick={() => toggleOpen()}
        className={"menu-trigger"}
      >
        <div className="icon-menu-line">
          <svg width="30" height="30" viewBox="0 0 30 30">
            <Path
              variants={{
                closed: { d: "M 2 2.5 L 20 2.5" },
                open: { d: "M 3 16.5 L 17 2.5" },
              }}
            />
            <Path
              d="M 2 9.423 L 20 9.423"
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              transition={{ duration: 0.1 }}
            />
            <Path
              variants={{
                closed: { d: "M 2 16.346 L 20 16.346" },
                open: { d: "M 3 2.5 L 17 16.346" },
              }}
            />
          </svg>
        </div>
      </button>

      <div className="desktop-menu">
        <ul>
          {MenuItems.map((menuItem, index) => (
            <li key={index}>
              <Link to={menuItem.path}>{menuItem.title}</Link>
            </li>
          ))}
          <li>
            <AnchorLink
              to="/about#contact"
              title="Let's Chat"
              className="button button--inverted"
            >
              Let's Chat
            </AnchorLink>
          </li>
        </ul>
      </div>

      <motion.ul
        className="mobile-menu"
        variants={variants}
        transition={{
          duration: 2,
          type: "spring",
          stiffness: 400,
          damping: 40,
        }}
      >
        {listMenuItems}
        <motion.li variants={listLinkVariants}>
          <Link
            to="/about#contact"
            title="Let's Chat"
            onClick={() => toggleOpen()}
            className="button button--inverted"
          >
            Let's Chat
          </Link>
        </motion.li>
      </motion.ul>
    </motion.nav>
  )
}

export default Navigation
