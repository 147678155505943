import React from "react"
import { AiOutlineMedium, AiOutlineTwitter } from "react-icons/ai"
import { FaBehance, FaLinkedinIn } from "react-icons/fa"

const date = new Date()

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p>Kennedy Iyeh {date.getFullYear()}</p>
      <div className="social-links">
        <a
          aria-label="Behance profile"
          href="http://behance.net/kikxle"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaBehance />
        </a>

        <a
          aria-label="LinkedIn profile"
          href="https://www.linkedin.com/in/kennedy-iyeh-a26771152/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </a>

        <a
          aria-label="Twitter profile"
          href="https://twitter.com/KennedyIyeh"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineTwitter />
        </a>

        <a
          aria-label="Medium profile"
          href="https://kennedyiyeh.medium.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineMedium />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
